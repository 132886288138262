import React from 'react'
import FacilityDetailsSidebar from './FacilityDetailsSidebar'
import {getConversion} from '../../api/images';

const FacilityDetailsContent = ({ facility, facilities }) => {
    const { media, name, description } = facility

    var image = media.filter(media => media.collection_name === 'facilities');

    return (
        <section className='blog-details-area ptb-50'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 col-md-12'>
                        <div className='blog-details-desc'>
                            {image.length !== 0 ?
                                <div className='article-image'>
                                    <img src={getConversion(image[0])} alt={name} />
                                </div>
                                : null
                            }
                            <div className='article-content'>
                                <h3>{name}</h3>
                                <div dangerouslySetInnerHTML={{ __html: description }} />

                                {facility && (facility.mo1 || facility.tu1 || facility.we1 || facility.th1 || facility.fr1 || facility.sa1 || facility.su1) &&
                                    <>
                                        <h5>Horario</h5>
                                        <table className="table table-sm table-responsive text-center">
                                            <thead>
                                                <tr >
                                                    <th>&nbsp;</th>
                                                    <th colSpan="2">Mañana</th>
                                                    <th colSpan="2">Tarde</th>

                                                </tr>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Inicio</th>
                                                    <th>Fin</th>
                                                    <th>Inicio</th>
                                                    <th>Fin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p><small>Lunes</small></p>
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.mo1 && facility.mo1.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.mo2 && facility.mo2.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.mo3 && facility.mo3.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.mo4 && facility.mo4.slice(0, -3)}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><small>Martes</small></p>
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.tu1 && facility.tu1.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.tu2 && facility.tu2.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.tu3 && facility.tu3.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.tu4 && facility.tu4.slice(0, -3)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><small>Miercoles</small></p>
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.we1 && facility.we1.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.we2 && facility.we2.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.we3 && facility.we3.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.we4 && facility.we4.slice(0, -3)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><small>Jueves</small></p>
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.th1 && facility.th1.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.th2 && facility.th2.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.th3 && facility.th3.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.th4 && facility.th4.slice(0, -3)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><small>Viernes</small></p>
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.fr1 && facility.fr1.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.fr2 && facility.fr2.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.fr3 && facility.fr3.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.fr4 && facility.fr4.slice(0, -3)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><small>Sábado</small></p>
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.sa1 && facility.sa1.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.sa2 && facility.sa2.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.sa3 && facility.sa3.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.sa4 && facility.sa4.slice(0, -3)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><small>Domingo</small></p>
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.su1 && facility.su1.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.su2 && facility.su2.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.su3 && facility.su3.slice(0, -3)}
                                                    </td>
                                                    <td className="text-center">
                                                        {facility.su4 && facility.su4.slice(0, -3)}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-12'>
                        <FacilityDetailsSidebar
                            facility={facility}
                            facilities={facilities}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FacilityDetailsContent