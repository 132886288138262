import api from './api'

export async function getFacility(slug) {
    api.get(`${process.env.API_URL}/facility?slug=${slug}`)
    .then(res => {
        return res.data
    })
    .catch(err => {
        console.log(err);
        return []
    })
}